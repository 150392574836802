<template>
  <div class="grid">
    <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="col-12 sticky">
      <div id="custom_card" class="card sticky">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
              <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                My Tickets
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6" v-if="user_type != 'Agent'">
            <div class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3">
              <Button iconPos="right" label="Create Ticket" icon="pi pi-plus" v-tooltip.bottom="'Create Ticket'"
                @click="openNew" size="small"
                style="box-shadow: 0 2px 6px rgba(0,176,176,.549);border-color: #00b0b0!important;background-color: #00b0b0!important;" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div id="custom_card" class="card ">
        <!-- <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" /> -->
        <div class="grid p-fluid">
          <div class="col-12 md:col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <InputText format="text" v-model="search_ticket" @change="search(1)">
                  </InputText>
                  <label>Search Ticket</label>
                </span>
              </div>
              <div class="field col-12 md:col-4">
                <span class="p-float-label">
                  <Dropdown id="Category" v-model="ticket_category_selected" :options="ticket_category_dropdown"
                    :value="ticket_category_dropdown" optionLabel="name" filter v-on:change="search(1)"></Dropdown>
                  <label for="Category">Category</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown id="Source" v-model="ticket_source_selected" :options="ticket_source_dropdown"
                    :value="ticket_source_dropdown" optionLabel="name" filter v-on:change="search(1)"></Dropdown>
                  <label for="Source">Source</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown id="status" v-model="ticket_status_selected" :options="ticket_status_dropdown"
                    :value="ticket_status_dropdown" optionLabel="marathi" filter v-on:change="search(1)"></Dropdown>
                  <label for="status">Status</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown id="ward_number" v-model="ward_no" :options="ward_number_dropdown"
                    :value="ward_number_dropdown" optionLabel="name" filter v-on:change="search(1)" />
                  <label for="ward_number">Ward Number</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown id="priority_id" v-model="priority_dropdown" :options="ticket_priority_dropdown"
                    :value="ticket_priority_dropdown" optionLabel="name" filter v-on:change="search(1)" />
                  <label for="priority_id">Priority</label>
                </span>
              </div>
              <div class="field col-12 md:col-2" v-if="user_type == 'Super Admin'">
                <span class="p-float-label">
                  <Dropdown id="client_dropdown" v-model="client_selected" :options="client_dropdown"
                    :value="client_dropdown" optionLabel="name" filter v-on:change="search(1)"></Dropdown>
                  <label for="Client">Client</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Calendar id="from_date" v-model="from_date" dateFormat="dd-mm-yy" :showIcon="true" :maxDate="to_date"
                    v-on:change="search(1)" />
                  <label for="from_date">From Date</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Calendar id="to_date" v-model="to_date" dateFormat="dd-mm-yy" :showIcon="true" :minDate="from_date"
                    v-on:change="search(1)" />
                  <label for="to_date">To Date</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <Button icon="pi pi-refresh" v-tooltip.bottom="'Refresh'" @click="search(2)" class="p-button-danger"
                  style=" box-shadow: 0 2px 6px #fd9b96;
                  border-color: #fc544b !important;
                  background-color: #fc544b !important;" />
              </div>
            </div>
          </div>
        </div>
        <DataTable :loading="loading" ref="dt" :lazy="true" :totalRecords="totalRecords" :paginator="true"
          :value="products" v-model:selection="selectedProducts" :dataKey="columns[0]" :rows="limit" :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" responsiveLayout="scroll"
          style="font-size: 12px" :rowHover="true" showGridlines>
          <template #empty>
            <b style="text-align: center">No records found.</b>
          </template>
          <template #header>
            <div style="text-align: right; font-weight: bold">
              <span>Total Tickets:</span>{{ totalRecords }}
            </div>
          </template>
          <Column style="min-width: 5rem">
            <template #body="{ data }">
              <div class="grid">
                <div class="col-12 md:col-1">
                  <img @click="editProduct(data)" style="border-radius: 50%; width: 80px; height: 80px"
                    v-if="data.customer_profile" :src="data.customer_profile" :alt="data.customer_profile" />
                  <!-- <img
                    style="border-radius: 50%; width: 80px; height: 80px"
                    v-else
                    src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o="
                  /> -->
                  <span @click="editProduct(data)" v-else-if="data.customer_name" class="i-circle"
                    :style="'background-color:' + getcolorcode(data.customer_name)">{{ convert_name(data.customer_name) }}</span>
                </div>
                <div class="col-12 md:col-7 text-left md:text-left">
                  <div @click="editProduct(data)">
                    <span style=" font-size: 15px; font-weight: 400">{{ data.customer_name }}</span>
                  </div>
                  <div style="margin-top: 5px; cursor: pointer" @click="editProduct(data)">
                    <span style=" font-size: 15px; font-weight: 700">
                      {{ data.title }}
                    </span>
                  </div>
                  <div style="margin-top: 5px; cursor: pointer" @click="editProduct(data)">
                    <span style=" font-size: 15px; font-weight: 700">
                      {{ data.ward_no }}
                    </span>
                  </div>
                  <div style="margin-top: 5px">
                    <div class="col-12">
                      <div class="p-fluid formgrid grid">
                        <div @click="editProduct(data)" class="field col-12 md:col-2" style="margin-top: 5px;">
                          <i class="pi pi-sitemap" style="color: blue;" />&nbsp;
                          <span style=" font-size: 15px; font-weight: 400">{{ data.ticket_category_name }}</span>
                        </div>
                        <div class="field col-12 md:col-1" v-if="data.agent_details.length > 0 && user_type == 'Admin'">
                          <!-- <AvatarGroup class="mb-2">
                            <div :key="index" v-for="(info, index) in data.agent_details">
                              <Avatar :image=info.agent_profile size="small" shape="circle"></Avatar>
                            </div>
                          </AvatarGroup> -->
                          <AvatarGroup class="mb-2">
                            <Avatar v-for="(info, index) in data.agent_details" :key="index"
                              :image="info.agent_profile ? info.agent_profile : 'images/eTicket_logo_new.png'" size="small"
                              shape="circle" @click="show_Agent(info, index)" v-tooltip.bottom="info.agent_name"
                              style="cursor: pointer;" class="zoom"></Avatar>
                          </AvatarGroup>
                        </div>
                        <div class="field col-12 md:col-1" v-if="user_type == 'Admin'">
                          <!-- <Button icon="pi pi-plus" class="p-button-rounded zoom" size="small" @click="edit_agent(data)" style="width:30px;height:30px"  v-tooltip.bottom="'Edit Agent'"/> -->
                          <Button icon="pi pi-plus" class="p-button-rounded zoom" size="small" @click="toggle(data)"
                            style="width:30px;height:30px" v-tooltip.bottom="'Edit Agent'" />
                          <OverlayPanel ref="op">
                            <div class="flex flex-column gap-3 w-25rem">
                              <h5>Add Agent </h5>
                              <div class="col-12">
                                <div class="p-fluid formgrid grid">
                                  <div class="field col-12 md:col-12">
                                    <span class="p-float-label">
                                      <MultiSelect v-model="agent_selected" :options="agent_dropdownItems"
                                        :value="agent_dropdownItems._id" optionLabel="name" filter display="chip"
                                        :maxSelectedLabels="10">
                                      </MultiSelect>
                                      <label for="name">Name</label>
                                    </span>
                                  </div>
                                  <div class="field col-12 md:col-4">
                                    <Button label="Save" @click="assign_agent"
                                      class="p-button-primary btn_light_blue" />
                                  </div>
                                  <div class="field col-12 md:col-4">
                                    <Button label="Cancel" @click="Cancel_Agent" class="p-button-danger btn_red" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </OverlayPanel>
                        </div>
                        <div class="field col-12 md:col-3" style="margin-top: 5px;" @click="editProduct(data)">
                          <i class="pi pi-clock" style="color: green;" />&nbsp;
                          <span style=" font-size: 15px; font-weight: 400">{{ this.D_M_Y_formatDate(data.date) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 md:col-4">
                  <div class="grid text-rigth md:text-right">
                    <div class="col-12 md:col-12">
                      <!-- <Dropdown
                    id="priority_id"
                    v-model="data['priority_id']"
                    :options="ticket_priority_dropdown"
                    :value="ticket_priority_dropdown._id" 
                    optionLabel="name"
                    filter
                    size="small"
                        @change="update_priority(data._id,data['priority_id'])"
                  ></Dropdown>
        &nbsp; -->
                      
                      <Tag class="mr-2" :style="'background-color:' + data['ticket_status']['colorcode']" :rounded="true">
                        {{ data['ticket_status']['marathi'] }}</Tag>
                      <Tag class="mr-4" :style="'background-color:' + getPriorityColor(data['priority_name'])" :rounded="true">
                        {{ data['priority_name'] }}
                      </Tag>
                      <Tag class="mr-4" :style="'background-color:' + getPriorityColor(data['priority_name'])" :rounded="true">
                        {{ data['priority_name'] }}
                      </Tag>
                      <Button icon="pi pi-pencil" class="p-button-rounded zoom" size="small" @click="toggle1(data)"
                          style="width:30px;height:30px; background-color: green" v-tooltip.bottom="'Edit Priority'" />
                        <OverlayPanel ref="op1">
                          <div class="flex flex-column gap-3 w-25rem">
                            <h5>Add Priority </h5>
                            <div class="col-12">
                              <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-12">
                                  <span class="p-float-label">
                                    <Dropdown id="priority_id" v-model="priority_selected"
                                      :options="ticket_priority_dropdown" :value="ticket_priority_dropdown"
                                      optionLabel="name" filter size="small"></Dropdown>
                                    <label for="name">Priority</label>
                                  </span>
                                </div>
                                <div class="field col-12 md:col-4">
                                  <Button label="Save" class="p-button-primary" @click="update_priority()" />
                                </div>
                                <div class="field col-12 md:col-4">
                                  <Button label="Cancel" class="p-button-danger" @click="cancelPriority" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </OverlayPanel>
                    </div>
                    <div class="col-12 md:col-12">
                      <!-- <Button
                        icon="pi pi-trash"
                        v-tooltip.bottom="'Delete'"
                        class="p-button-rounded p-button-danger mt-2"
                        @click="confirmDeleteProduct(data)"
                        style=" box-shadow: 0 2px 6px #fd9b96;
                  border-color: #fc544b !important;
                  background-color: #fc544b !important;"
                      /> -->
                      <!-- ----------------- -->
                      <!-- <Button
                        icon="pi pi-trash"
                        v-tooltip.bottom="'Delete'"
                        class="p-button-rounded p-button-danger mt-2"
                        style=" box-shadow: 0 2px 6px #fd9b96;
                            border-color: #fc544b !important;
                            background-color: #fc544b !important;"
                        @click="delete_toggle(data)"
                      /> -->
                      <OverlayPanel ref="del">
                        <div class="flex flex-column gap-3 w-25rem">
                          <h5>Delete Ticket </h5>
                          <div class="col-12">
                            <div class="p-fluid formgrid grid">
                              <div class="field col-12 md:col-12">
                                <span class="p-float-label">
                                  <Textarea format="text" v-model="delete_remark">
                                      </Textarea>
                                  <label>Remark</label>
                                </span>
                              </div>
                              <div class="field col-12 md:col-4">
                                <Button label="Delete" @click="delete_ticket" class="p-button-primary btn_light_blue" />
                              </div>
                              <div class="field col-12 md:col-4">
                                <Button label="Cancel" @click="Cancel_delete" class="p-button-danger btn_red" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </OverlayPanel>
                      <!-- ----------------- -->
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="product">Are you sure you want to delete ?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
            <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteuser" />
          </template>
        </Dialog>
        <Dialog v-model:visible="edit_agent_model" :breakpoints="{ '960px': '75vw' }" :style="{ width: '60vw' }"
          header="Add Agent" :modal="true" position="top">
          <div class="col-12">
            <div class="p-fluid formgrid grid" style="margin-top: 2%;">
              <div class="field col-12 md:col-12" style="margin-top: 1%;">
                <span class="p-float-label">
                  <MultiSelect v-model="agent_selected" :options="agent_dropdownItems" :value="agent_dropdownItems._id"
                    optionLabel="name" filter display="chip" :maxSelectedLabels="10">
                  </MultiSelect>
                  <label for="name">Name</label>
                </span>
              </div>
            </div>
          </div>
          <template #footer>
            <Button label="Save" @click="assign_agent" icon="pi pi-check" class="p-button-success" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
import ColorService from "../service/ColorService";
import apis from "@/apis";
import axios from "axios";
import BreadcrumbService from "../service/BreadcrumbService";
import JsonExcel from "vue-json-excel";
export default {
  data() {
    return {
      //search
      from_date: "",
      to_date: "",
      ward_no: "",
      temp_ticket_id: "",
      delete_remark: "",
      ColorService: null,
      color_data: [],
      agent_row_data: [],
      rowdata: [],
      priority_selected: [],
      edit_agent_model: false,
      search_ticket: "",
      priority_dropdown: "",
      category_id: "",
      agent_dropdownItems: [],
      ward_number_dropdown: [],
      agent_selected: "",
      ticket_category_dropdown: [],
      ticket_category_selected: "",
      ticket_priority: "",
      status_id: "",
      ticket_status_dropdown: [],
      ticket_priority_dropdown: [],
      ticket_status_selected: "",
      user_type: "",
      ticket_source_dropdown: [],
      ticket_source_selected: "",
      source_id: "",
      client_dropdown: [],
      client_selected: "",
      clientid: "",
      page_no: 1,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      limit: 10,
      columns: [],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      BreadcrumbService: null,
      breadcrumbHome: {},
      breadcrumbItems: [],
      selected_data: '',
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.ColorService = new ColorService();
    this.BreadcrumbService = new BreadcrumbService();
    let Breadcrum_data = this.BreadcrumbService.getBreadcrum(
      "Ticket",
      "/tickets"
    );
    this.breadcrumbHome = Breadcrum_data.home;
    this.breadcrumbItems = Breadcrum_data.items;
    await this.getPriorityList();
    await this.getTicketStatusMaster();
    await this.getTicketCategoryMaster();
    await this.getAgentMaster();
    await this.getTicketSourceMaster();
    await this.getClientMaster();
    await this.getWardList();
  },
  async mounted() {
    this.ColorService.getColors().then((colors) => {
      this.color_data = colors;
    });
    this.id = this.$route.params.id;
    this.user_type = localStorage.getItem("user_type");
    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {

    getPriorityColor(priorityName) {
      const priorityColors = {
        High: '#FF4219',    
        Medium: '#00FFC3',
        Low: '#C300FF',   
        Critical: '#C70039',
      };

      // Default color if the priority name doesn't match any key
      return priorityColors[priorityName] || '#CCCCCC';
    },
    toggle1(data) {
      this.priority_selected = [];
      this.rowdata = [];
      this.priority_selected = data.priority_id;
      this.rowdata = data;
      this.$refs.op1.toggle(event);
    },
    cancelPriority() {
      // this.data['priority_id'] = null; 
      this.$refs.op1.hide();
    },
    async update_priority() {
      let details = {
        "priority_id": this.priority_selected._id,
        "_id": this.rowdata._id
      }
      var promise = apis.updatepriority(details);
      promise.then((response) => {
        this.$swal(response.data.message);
        this.get_list();
        this.get_count();
      });
    },
    getBackgroundColor(data) {
      if (data.name === 'Medium') {
        return '#ffc107 !important';
      }
      if (data.name === '') {
        return '#ffc107 !important';
      }
      else if (data.name === 'Low') {
        return '#00B064 !important';
      } else if (data.name === 'High') {
        return '#fc544b !important';
      }
    },
    bordercolor(data) {
      if (data.name === 'Medium') {
        return '#ffc107 !important';
      } else if (data.name === 'Low') {
        return '#00B064 !important';
      } else if (data.name === 'High') {
        return '#fc544b !important';
      }
    },
    boxshadow(data) {
      if (data.name === 'Medium') {
        return '0 2px 6px #ffc107';
      } else if (data.name === 'Low') {
        return '0 2px 6px #00B064';
      } else if (data.name === 'High') {
        return '0 2px 6px #fd9b96';
      }
    },
    getcolorcode(name) {
      var name = this.convert_name(name)
      var temp = name.substring(0, 1);
      for (let index = 0; index < this.color_data.length; index++) {
        if (this.color_data[index].name == temp) {
          return this.color_data[index].code;
        }
      }
    },
    convert_name(name) {
      var x = this.ColorService.convertToSN(name);
      return x.toString();
    },
    Cancel_Agent() {
      this.agent_selected = [];
      this.agent_row_data = [];
      this.$refs.op.hide();
    },
    toggle(data) {
      this.agent_selected = [];
      this.agent_row_data = [];
      var temp = [];
      for (let index = 0; index < data.agent_details.length; index++) {
        temp.push({ _id: data.agent_details[index].agent_id, name: data.agent_details[index].agent_name });
      }
      this.agent_selected = temp;
      this.agent_row_data = data;
      this.$refs.op.toggle(event);
    },
    toggle_priority(data) {
      this.data['priority_id'] = [];
      var temp = [];
      for (let index = 0; index < data.priority_details.length; index++) {
        temp.push({ _id: data.priority_details[index].agent_id, name: data.priority_details[index].agent_name });
      }
      this.data['priority_id'] = temp;
      this.agent_row_data = data;
      this.$refs.op.toggle(event);
    },
    async edit_agent(data) {
      var temp = [];
      for (let index = 0; index < data.agent_details.length; index++) {
        temp.push({ _id: data.agent_details[index].agent_id, name: data.agent_details[index].agent_name });
      }
      this.agent_selected = temp;
      this.agent_row_data = data;
      this.edit_agent_model = true;
    },
    async show_Agent(info, index) {
      return false;
      await localStorage.setItem('AgentDetails', '');
      await localStorage.setItem('AgentDetails', JSON.stringify(info));
      //data not present
      this.$router.push({ name: "agentdetails" });
    },
    D_M_Y_formatDate(date_parm) {
      if (date_parm) {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd + "/" + mm + "/" + yyyy;
        // alert(dd)
        if (dd.toString() != "NaN") {
          //console.log(new_date);
          return new_date;
        } else {
          return date_parm;
        }
      }
    },
    async assign_agent() {
      var agent = [];
      for (let index = 0; index < this.agent_selected.length; index++) {
        agent.push(this.agent_selected[index]._id);
      }
      let details = {
        "ticket_no": this.agent_row_data.ticket_no,
        "client_id": "65a4f82f6177e69880ece5d6",
        "agent_id": agent,
        "user_id": localStorage.getItem("id")
      }
      var promise = apis.assignAgent(details);
      promise.then((response) => {
        this.$swal(response.data.message);
        this.edit_agent_model = false;
        this.$refs.op.hide();
        this.get_list();
        this.get_count();
      });
    },
    async update_status(ticket_no, status) {
      if (!ticket_no || !status) {
        this.$swal("Error !!!");
      }
      let details = {
        "user_id": localStorage.getItem("id"),
        "ticket_no": ticket_no,
        "client_id": localStorage.getItem("client_id"),
        "status": status._id,
      }
      var promise = apis.updateTicketStatus(details);
      promise.then((response) => {
        this.$swal(response.data.message);
        this.get_list();
        this.get_count();
      });
    },
    async search(count) {
      if (count == 2) {
        this.search_ticket = "";
        this.category_id = "";
        this.from_date = "";
        this.to_date = "";
        this.ward_no = "";
        this.priority_dropdown = "";
        this.ticket_category_selected = "";
        this.ticket_source_selected = "";
        this.status_id = "";
        this.ticket_status_selected = "";
        this.client_selected = "";
        this.clientid = "";
      }
      await this.get_list();
      await this.get_count();
    },
    async viewProduct(product) { },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    async getTicketCategoryMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
      };
      // this.isLoadingModel = true;
      var promise = apis.TicketCategoryMaster(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ticket_category_dropdown = response.data.data;
      });
    },
    async getWardList() {
      var data = {
        //client_id: "65a4f82f6177e69880ece5d6",
      };
      // this.isLoadingModel = true;
      var promise = apis.getWardList(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ward_number_dropdown = response.data.data;
      });
    },
    async getPriorityList() {
      var data = {
        //client_id: "65a4f82f6177e69880ece5d6",
      };
      // this.isLoadingModel = true;
      var promise = apis.getPriorityList(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ticket_priority_dropdown = response.data.data;
      });
    },
    async getTicketSourceMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
      };
      var promise = apis.getTicketSource(data);
      promise.then((response) => {
        var local_temp = response.data.data;
        var local_array = [];
        for (let index = 0; index < local_temp.length; index++) {
          local_array.push({ _id: local_temp[index].name, name: local_temp[index].name })
        }
        this.ticket_source_dropdown = local_array;
      });
    },
    async getAgentMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
      };
      var promise = apis.agentListDropdown(data);
      promise.then((response) => {
        var temp = [];
        for (let index = 0; index < response.data.data.length; index++) {
          temp.push({ _id: response.data.data[index].agent_id, name: response.data.data[index].agent_name });
        }
        this.agent_dropdownItems = temp;
      });
    },
    async getTicketStatusMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
      };
      // this.isLoadingModel = true;
      var promise = apis.TicketStatusMaster(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ticket_status_dropdown = response.data.data;
        var ticket_status = localStorage.getItem("ticket_status");
        //////////////////////////////////////////////////////////////////////////////////////
        for (let i = 0; i < this.ticket_status_dropdown.length; i++) {
          if (this.ticket_status_dropdown[i]['_id'] == ticket_status) {
            this.ticket_status_selected = this.ticket_status_dropdown[i];
          }
        }
        localStorage.setItem("ticket_status", '');
      });
      //////////////////////////////////////////////////////////////////////////
    },
    format_date_as_dd_mm_yyyy() {
      let date = new Date();
      let dd = date.getDate();
      let mm = date.getMonth();
      let yyyy = date.getFullYear().toString();
      let tt = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      let new_date = dd + "-" + mm + "-" + yyyy;
      return new_date;
    },
    async getClientMaster() {
      var data = {
        limit: 10000,
        page_no: 1,
        count: false,
      };
      var promise = apis.getclientist(data);
      promise.then((response) => {
        this.client_dropdown = response.data.data;
      });
    },
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = d.getFullYear();
      // Return in desired format, e.g., DD/MM/YYYY
      return `${day}/${month}/${year}`;
    },
    get_count: function () {
      var customer_id = "";
      if (localStorage.getItem("user_type") == "Customer") {
        customer_id = localStorage.getItem("id");
      }
      var agent_id = "";
      if (localStorage.getItem("user_type") == "Agent") {
        agent_id = localStorage.getItem("id");
      }
      // var ticket_status = localStorage.getItem("ticket_status") || "";
      //     var status_id = "";
      //     if (ticket_status === "प्रलंबित") {
      //       status_id = "pending_id";
      //     } else if (ticket_status === "बंद") {
      //       status_id = "closed_id"; 
      //     }
      //get category id
      this.category_id = this.ticket_category_selected._id;
      //get status
      // this.status_id = this.ticket_status_selected._id;
      //
      this.source_id = this.ticket_source_selected._id;
      //
      this.clientid = "";
      this.clientid = this.client_selected._id;
      if (this.clientid == '' || this.clientid == undefined) {
        this.clientid = localStorage.getItem("client_id");
      }
      var ward_no = '';
      if (this.ward_no) {
        ward_no = this.ward_no.ward_no;
      }
      var priority = '';
      if (this.priority_dropdown) {
        priority = this.priority_dropdown._id;
      }
      var data = {
        user_id: localStorage.getItem("id"),
        customer_id: customer_id,
        category: this.category_id,
        title: "",
        category: "",
        type: "",
        status: this.status_id,
        client_id: this.clientid,
        ticket_no: this.search_ticket,
        agent_id: agent_id,
        department_id: "",
        count: true,
        source: this.source_id,
        //  from_date: this.from_date, 
        //   to_date: this.to_date,   
        from_date: this.formatDate(this.from_date),
        to_date: this.formatDate(this.to_date),
        status_name: this.status_name,
        "ward_no": ward_no,
        priority_id: priority
      };
      this.loading = true;
      var promise = apis.ticketlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.totalRecords = response.data.data;
      });
      //console.log(data);
    },
    dateFormatYMD(inputDate) {
      var postdate = inputDate;
      if (inputDate) {
        inputDate = new Date(inputDate);
        if (inputDate.toString() == "NaN-NaN-NaN") {
          return postdate;
        } else {
          let date = ("0" + inputDate.getDate()).slice(-2);
          // current month
          let month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
          // current year
          let year = inputDate.getFullYear();
          if (date.toString() == "aN") {
            return postdate;
          } else {
            return year + "-" + month + "-" + date;
          }
        }
      } else {
        return "";
      }
    },
    async get_list() {
      var customer_id = "";
      if (localStorage.getItem("user_type") == "Customer") {
        customer_id = localStorage.getItem("id");
      }
      var agent_id = "";
      if (localStorage.getItem("user_type") == "Agent") {
        agent_id = localStorage.getItem("id");
      }
      // var status_id = "";
      // if (localStorage.getItem("ticketStatus") == "प्रलंबित") {
      //   status_id = localStorage.getItem("ticketStatus");
      // }
      // var status_id = "";
      // if (localStorage.getItem("ticketStatus") == "बंद") {
      //   status_id = localStorage.getItem("ticketStatus");
      // }
      //get category id
      this.category_id = this.ticket_category_selected._id;
      //get status
      ///////////////////////////////////////////////////////////////////////
      if (this.ticket_status_selected._id) {
        this.status_id = this.ticket_status_selected._id;
      }
      else if (localStorage.getItem('ticket_status')) {
        this.status_id = localStorage.getItem('ticket_status');
      }
      else {
        this.status_id = '';
      }
      var ward_no = '';
      if (this.ward_no) {
        ward_no = this.ward_no.ward_no;
      }
      var priority = '';
      if (this.priority_dropdown) {
        priority = this.priority_dropdown._id;
      }
      ////////////////////////////////////////////////
      this.source_id = this.ticket_source_selected._id;
      this.clientid = "";
      this.clientid = this.client_selected._id;
      if (this.clientid == '' || this.clientid == undefined) {
        this.clientid = localStorage.getItem("client_id");
      }
      var data = {
        user_id: localStorage.getItem("id"),
        customer_id: customer_id,
        title: "",
        category: this.category_id,
        type: "",
        status: this.status_id,
        client_id: this.clientid,
        ticket_no: this.search_ticket,
        agent_id: agent_id,
        department_id: "",
        page_no: this.page_no,
        limit: this.limit,
        count: false,
        source: this.source_id,
        // from_date: this.from_date, 
        //   to_date: this.to_date,    
        from_date: this.formatDate(this.from_date),
        to_date: this.formatDate(this.to_date),
        status_name: this.status_name,
        ward_no: ward_no,
        priority_id: priority
      };
      this.loading = true;
      var promise = apis.ticketlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.products = response.data.data;
        //   for (let i = 0; i < this.products.length; i++) {
        //   for (let j = 0; j < this.products[i].agent_details.length; j++) {
        //     if (this.products[i].agent_details[j].agent_profile == "") {
        //      // console.log("blank",this.products[i].agent_details[j].agent_profile);
        //       this.products[i].agent_details[j].agent_profile = "images/eTicket_logo_new.png";
        //     }
        //   }
        // }
        // for (this.products[0] = 0; this.products[0] < this.products[0].length; this.products[0]++) {
        //   if (this.products[0].agent_details == "") {
        //     console.log(this.products[0].agent_details);
        //   } 
        // }
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
      this.$router.push({ name: "create_ticket" });
    },
    async editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
      await localStorage.setItem('TicketDetails', '');
      await localStorage.setItem('TicketDetails', JSON.stringify(product));
      this.$router.push({ name: "ticket_details" });
      //console.log(localStorage.getItem("TicketDetails"));
    },
    confirmDeleteProduct(product) {
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to Delete ticket?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          var payload =
          {
            "client_id": "65a4f82f6177e69880ece5d6",
            "user_id": localStorage.getItem("id"),
            "ticket_no": product.ticket_no
          }
          this.isLoadingModel = true;
          var promise = apis.deleteTicket(payload);
          promise.then((response) => {
            this.isLoadingModel = false;
            if (response.data.status == true) {
              this.$swal(response.data.message);
              this.get_list();
              this.get_count();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            else {
              this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
            }
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Cancel', detail: 'You have Cancelled', life: 3000 });
        }
      });
    },
    delete_toggle(data) {
      this.temp_ticket_id = data.ticket_no;
      this.$refs.del.toggle(event);
    },
    delete_ticket() {
      if (this.delete_remark == null || this.delete_remark == "" || this.delete_remark == undefined) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Remark",
          life: 3000,
        });
        return false;
      }
      else {
        var payload =
        {
          "client_id": "65a4f82f6177e69880ece5d6",
          "user_id": localStorage.getItem("id"),
          "ticket_no": this.temp_ticket_id,
          "remark": this.delete_remark
        }
        this.isLoadingModel = true;
        var promise = apis.deleteTicket(payload);
        promise.then((response) => {
          this.isLoadingModel = false;
          if (response.data.status == true) {
            this.$swal(response.data.message);
            this.get_list();
            this.get_count();
            this.temp_ticket_id = "";
            this.delete_remark = "";
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
          else {
            this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
          }
        });
      }
    },
    Cancel_delete() {
      this.temp_ticket_id = "";
      this.delete_remark = "";
      this.$refs.del.hide();
    },
    async exportCSV() {
      this.limit = this.totalRecords;
      this.get_list();
      await this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: { downloadExcel: JsonExcel },
};
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";

#custom_card {
  border-top: 3px solid #6b719b;
}

.In_Progress {
  background-color: yellow;
  color: white
    /* Adjust the styling as per your requirement */
}

.Open {
  background-color: green;
  color: white
    /* Adjust the styling as per your requirement */
}

.Closed {
  background-color: red;
  color: white
    /* Adjust the styling as per your requirement */
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
}
</style>